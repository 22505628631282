
import companies from './companies.jsx'
import './style/CompanyCarrousel.css'
export default function CompaniesCarrousel() {

    const source = companies;

    let listCompanies = source.map(company => {
        return (
            <li className={company.name} key={company.id}>
                <img className={company.name} src={company.imageURL} alt={company.name} />
            </li>
        );
    });

    return (
        <div className='companies-carrousel-container'>
            <h2 className='companies-carrousel-title' >Las empresas que nos acompañan:</h2>
            <ul>
                {listCompanies}
            </ul>
        </div>
    )

}
