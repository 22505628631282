import React, { useState } from 'react';
import emailContactSender from '../../services/emailContactSender.js';
// const socialmedia = require('../../config/socialmedia.json');
import socialmedia from '../../config/socialmedia.json';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import './style/ContactSection.css';

//-- Utils, formValidations
function EmailValidation({ email }) {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
}

function BannedWordsValidation({ message }) {
    const bannedWords = ['hacker', 'vandal', 'cyber', 'puto', 'gay', 'chorro', 'chorros', 'gay'];
    const lowerCaseMessage = message.toLowerCase();
    return bannedWords.some((word) => lowerCaseMessage.includes(word));
}



export default function ContactSection() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [topic, setTopic] = useState('');
    const [message, setMessage] = useState('');

    const handleNameChange = (e) => {
        const name = e.target.value;
        const maxMessageLength = 20;
        if (name.length > maxMessageLength) {
            return
        }

        const regex = /^[a-zA-Z0-9\s]*$/;
        if (regex.test(name)) {
            setName(name);
        }

    }

    const handleEmailChange = (e) => {
        const email = e.target.value;
        if (EmailValidation({ email })) {
            setEmail(email);
        }
    }

    const handleTopicChange = (e) => {
        const topic = e.target.value;
        setTopic(topic);
    }

    const handleMessageChange = (e) => {
        const message = e.target.value;
        console.log(message);

        if (!BannedWordsValidation({ message })) {
            setMessage(message);
        }

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log({ name, email, topic, message });
        if (name && email && message) {
            alert('Enviado');
            console.log({ name, email, message });
            emailContactSender({ name, email, message });
        }
    };

    return (
        <section id="contact-section" >
            <h3>Contacto</h3>
            <hr></hr>
            <div className='contact-container'>
                <div className='contact-info'>
                    <p>Lunes a viernes de 9:00 a 15:00 Hs.</p>
                    <div className='contact-data '>
                        <FontAwesomeIcon icon={faWhatsapp} />
                        <span>+54 9 11 5555-5555   </span>
                    </div>
                    <div className='contact-data '>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <span>contacto@mutualbuonaterra.com.ar</span>
                    </div>
                    <div className="socialmedia">
                        <a href={socialmedia.facebook} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebook} />
                        </a>
                        <a href={socialmedia.instagram} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a href={socialmedia.whatsapp} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faWhatsapp} />
                        </a>
                    </div>
                </div>

                <form className='contact-form' onSubmit={(e) => handleSubmit(e)} >
                    <input type="text" placeholder="Nombre" name='name' onChange={(e) => handleNameChange(e)} required />
                    <input type="text" placeholder="Email" name='email' onChange={(e) => handleEmailChange(e)} required />
                    <input type="text" placeholder="Asunto" name='topic' onChange={(e) => handleTopicChange(e)} />
                    <textarea placeholder="Mensaje" name='message' onChange={(e) => handleMessageChange(e)} required />
                    <button type="submit" id='contact-button-form'>Enviar</button>
                </form>
            </div>
        </section>
    )
}