
import './style/Footer.css';

export default function Footer() {
    return (
        <footer>
            {/* <h4 className="footer-title">Mutual Buona Terra</h4>

            <div className="footer-key-words">
                <div className='key-word-first-column'>
                    <h5 className="key-word"> Asociarse</h5>
                    <h5 className="key-word"> Servicios</h5>
                    <h5 className="key-word"> Salud</h5>
                </div>
                <div className='key-word-second-column'>
                    <h5 className="key-word"> Comercio Exterior</h5>
                    <h5 className="key-word"> Comercio Interno</h5>
                    <h5 className="key-word"> Productores Locales</h5>

                </div>
                <div className='key-word-third-column'>
                    <h5 className="key-word"> Paginas Web</h5>
                    <h5 className="key-word"> Desarrollo de Software</h5>
                </div>
                <div className='key-word-fourth-column'>
                    <h5 className="key-word"> Contacto</h5>
                    <div className="socialmedia">
                        <FontAwesomeIcon icon={faFacebook} />
                        <FontAwesomeIcon icon={faInstagram} />
                        <FontAwesomeIcon icon={faWhatsapp} />
                    </div>
                </div>
            </div> */}

            <div className="footer-copyright">
                <p>Copyright © 2024 BUONA TERRA. Todos los derechos reservados.</p>
            </div>
        </footer>
    )
}