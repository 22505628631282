import './styles/App.css';
import Header from './components/Header/Header.jsx';
import Title from './components/Title/Title.jsx';
import NavSection from './components/NavSection/NavSection.jsx';
import CallToAction from './components/CallToAction/CallToAction.jsx';
import CompaniesCarrousel from './components/CompaniesCarrousel/CompaniesCarrousel.jsx';
import ContactSection from './components/Contact/ContactSection.jsx';
import Footer from './components/Footer/Footer.jsx';


function App() {

    return (
        <div className="App">
            <Header ></Header>
            <Title ></Title>
            <NavSection ></NavSection>
            <CallToAction ></CallToAction>
            <CompaniesCarrousel ></CompaniesCarrousel>
            <ContactSection ></ContactSection>
            <Footer ></Footer>
        </div>
    );
}

export default App;
