import React from "react";
import './styles/CallToAction.css';


const DualDivs = () => {

    return (
        <div className="CallToAction">
            <div className="wrapper">
                <div className="left-div">
                    <h2 className="left-h2"> ¿Conoces nuestros beneficios?</h2>
                    <p className="left-p">En constante actualización para darte las mejores soluciones</p>
                </div>
                <div className="right-div">
                    <button className="right-button" src='#contact-section'>Asociarme</button>
                    <p className="right-p">¡Sumate a nuestra comuinidad!</p>
                </div>
            </div>
        </div>
    );
};


export default DualDivs;