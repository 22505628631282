const APIURL = 'http://localhost:4000/BT';

const emailContactSender = async ({ name, email, message }) => {
    console.log('try: ', { name, email, message })
    try {
        const response = await fetch(`${APIURL}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name,
                email,
                message
            })
        });
        const data = await response.json();
        console.log(data);
    } catch (error) {
        console.error(error);
    }
}


export default emailContactSender