import './style/Title.css'

export default function Title() {
    return (
        <div className="Title-App">
            <img alt="Mutual Buona Terra" src="./img/Mini_logos_PNG/BuonaTerra.png"></img>
            
            <h3>La solucion que buscas</h3>
        </div>
    );
}