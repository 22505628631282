
const companies = [
        { id: 1, name: "FARMACIAS_PREVILEY", imageURL: "/img/Mini_logos_PNG/FARMACIAS_PREVILEY.png"},
        { id: 2, name: "ABRAHAM", imageURL: "/img/Mini_logos_PNG/ABRAHAM.png"},
        { id: 3, name: "GEOSER", imageURL: "/img/Mini_logos_PNG/GEOSER.png"},
        { id: 4, name: "AMEC", imageURL: "/img/Mini_logos_PNG/AMEC.png"},
        { id: 5, name: "SOCIEDAD_RURAL", imageURL: "/img/Mini_logos_PNG/SOCIEDAD_RURAL.png"}
    ];
export default companies;

