import { useEffect, useState } from "react";
import './styles/navSection.css';

export default function NavSection() {

    const [activeButton, setActiveButton] = useState('BTHome'); // start button
    const [isCycling, setIsCycling] = useState(true);           // Flag 

    useEffect(() => {
        const buttonList = ['BTHome', 'BTCode', 'BTSalud', 'BTComex'];
        let interval;
        if (isCycling) {  
            interval = setInterval(() => {
                setActiveButton(prevButton => {
                    const currentIndex = buttonList.indexOf(prevButton);
                    const nextIndex = (currentIndex + 1) % buttonList.length;
                    return buttonList[nextIndex];
                });
            }, 4000); // 4s
        }

        return () => clearInterval(interval); // Limpiar al desmontar el componente
    }, [isCycling]);

    const handleButtonClick = (buttonName) => {
        setIsCycling(false); 
        setActiveButton(buttonName);
    };

    return (
        <div className="navSection">

            <ul className="buttonList">
                <li className="BTHome" onClick={() => handleButtonClick('BTHome')}></li>
                <li className="BTCode" onClick={() => handleButtonClick('BTCode')}></li>
                <li className="BTSalud" onClick={() => handleButtonClick('BTSalud')}></li>
                <li className="BTComex" onClick={() => handleButtonClick('BTComex')}></li>
            </ul>

            <div id="carrousel" style={{ display: 'flex', justifyContent: 'space-between' }}>

                <div className={`BTHome_img ${activeButton === 'BTHome' ? 'active' : ''}`}>
                    <div className="container">
                        <img className="BT" src="/img/Mini_logos_PNG/BT.png" alt="BT ICON" />
                    </div>
                    <p className="BT_italic">La solucion que buscas</p>
                    <p className="Underline">&nbsp;</p>
                    <p className="BT_text"> En crecimiento permanente, generando<br/>  nuevos servicios para nuestros asociados.</p>
                </div>

                <div className={`BTCode_img ${activeButton === 'BTCode' ? 'active' : ''}`}>
                    <div className="container">
                        <img className="BTCODE" src="/img/Mini_logos_PNG/bt_code_carrousel.png" alt="BTCODE ICON" />
                    </div>    
                    <ul className="list" id="code">
                        <li className="element">Desarrollo de Paginas Web</li>
                        <li className="element">Desarrollo de Software a Medida</li>
                        <li className="element">Administracion de base de datos</li>
                    </ul>
                </div>

                <div className={`BTSalud_img ${activeButton === 'BTSalud' ? 'active' : ''}`}>
                    <div className="container">
                        <img className="BTSALUD" src="/img/Mini_logos_PNG/bt_salud_carrousel.png" alt="BTSALUD ICON" />
                    </div>
                    <ul className="list" id="salud">
                        <li className="element">Descuentos en Farmacias</li>
                        <li className="element">Descuentos en Analisis Clinicos</li>
                        <li className="element">Servicio de Traslados en Ambulancia</li>
                        <li className="element">Servicio de Enfermeria</li>
                    </ul>
                </div>

                <div className={`BTComex_img ${activeButton === 'BTComex' ? 'active' : ''}`}>
                    <div className="container">
                        <img className="BTCOMEX" src="/img/Mini_logos_PNG/bt_comex_carrousel.png" alt="BTCOMEX ICON" />
                    </div>    
                    <p className="BT_comex_subtitle">En asociacion con la Sociedad Rural de la prov. de Bs. As.</p>
                    <ul className="list">
                        <li className="element">Conexion de pequeños y medianos productores con el mercado</li>
                        <li className="element">Comercio internacional</li>
                        <li className="element">Comercio Nacional</li>
                        <li className="element">Participacion de la incubadora "PRECISAR"</li>
                    </ul>
                </div>

            </div>
        </div>
    );
}