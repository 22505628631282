import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import socialmedia from '../../config/socialmedia.json';
// const socialmedia = require('../../config/socialmedia.json');


import './style/header.css'

export default function Header() {
    return (
        <header className='Header-App'>
            <div className='contact-button-container'>
                <a href="#contact-section" id="header-contact-button">
                    Contáctanos
                </a>
            </div>


            <div className='right-container'>
                <div className='contact-data'>
                    <FontAwesomeIcon icon={faClock} />
                    <span> Lunes a viernes de 9:00 a 15:00 Hs.</span>
                </div>
                <div className='contact-data'>
                    <FontAwesomeIcon icon={faLocationDot} />
                    <span> San Lorenzo 3864 - Mar del Plata</span>
                </div>
                <div className="socialmedia">
                    <a href={socialmedia.facebook} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    <a href={socialmedia.instagram} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href={socialmedia.whatsapp} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faWhatsapp} />
                    </a>
                </div>
            </div>
        </header>
    );
}